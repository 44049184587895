import React from "react"

import Layout from "../layouts/pt"
import SEO from "../components/SEO"
import Metomic from "../components/Metomic"
import SplashScreens from "../components/SplashScreens"
import Header from "../components/Header.pt"
import Accordions from "../components/Accordions"

import "react-bootstrap-typeahead/css/Typeahead.css"

const accordionData = [
  {
    accordionCategory: "Introdução",
    accordionCard: [
      {
        title: "Como assim designs ilimitados?",
        content:
          "Você pode solicitar quantos designs desejar e seus pedidos serão processados um a um, na ordem em que são recebidos, o mais rápido possível. Ou seja, trabalharemos em uma demanda de cada vez e, quando você estiver satisfeito com o seu pedido, iniciaremos imediatamente o próximo.",
      },
      {
        title: "Quanto tempo leva para concluir uma tarefa de design?",
        content:
          "Começaremos a trabalhar no seu pedido dentro de algumas horas úteis após recebê-lo. Em seguida, entregaremos seu pedido para revisão entre 2 e 5 dias úteis, dependendo do seu plano e da complexidade do seu design. Se quiser que façamos outras revisões, continuaremos trabalhando até que você esteja feliz.",
      },
      {
        title: "E as revisões também são ilimitadas?",
        content:
          "São ilimitadas sim! Nós queremos que você esteja completamente feliz com o seu design. Peça quantos ajustes forem necessários e nós trabalharemos em seu projeto até que você fique satisfeito!",
      },
      {
        title: "Quanto tempo leva uma revisão? ",
        content:
          "Normalmente cada revisão leva no mínimo um dia útil. Entretanto pode acontecer de você precisar de uma alteração mais complexa e que tomará mais tempo. Nestes casos, fornecemos uma 'cotação de tempo' para realizar os ajustes.",
      },
      {
        title: "Como faço para enviar minhas tarefas de design?",
        content:
          "Inicialmente, você precisará preencher nosso formulário personalizado de solicitação de design, que você encontrará no painel de controle do website logo após concluir o seu processo de cadastro.",
      },
      {
        title: "Vocês entregam um produto físico?",
        content: "Não. Nossos designs são 100% digitais.",
      },
      {
        title: "Quais ferramentas de design são usadas para criar meus projetos?",
        content:
          "Usamos as versões mais recentes do Adobe Creative Suite, principalmente o Photoshop, o Illustrator e o InDesign — as ferramentas mais utilizadas nas criações dos projetos criativos. Se o seu plano incluir Web Design nós também podemos entregar projetos feitos em Sketch.",
      },
      {
        title: "A Jubarte faz todos os tipos de trabalho de design?",
        content:
          "Desde que seja um pedido de design gráfico estático, fazemos sim! Aqui temos alguns exemplos de projetos que nós não fazemos: edição de vídeo, animações, CAD, trabalho em 3D, programação e codificação. Mesmo assim, se você quiser verificar algum projeto específico, não pense duas vezes, pergunte para algum dos nossos atendentes no chat ou envie um email para suporte@jubarte.design.",
      },
      {
        title: "O que é uma cotação de tempo?",
        content:
          "Cotações de tempo são normalmente utilizadas para pedidos complexos. Sempre que nos enviar um pedido de design considerado complexo, seu designer ou gerente de conta enviará uma estimativa de prazo para completar o seu pedido. Isso é o que chamamos de 'cotação de tempo'.",
      },
    ],
  },
  {
    accordionCategory: "Atendimento",
    accordionCard: [
      {
        title: "Posso falar com o meu designer?",
        content: "Claro que sim! Você pode falar com seu designer e/ou gerente de contas por email ou chat.",
      },
      {
        title: "E se eu tiver um pedido de design complexo?",
        content:
          "Nos diga o que você precisa. Avaliaremos e se pudermos te atender, fornecemos uma cotação de tempo personalizada.",
      },
      {
        title: "Vocês trabalham nos finais de semana?",
        content:
          "Não, aos finais de semana nosso time gosta de explorar águas profundas para meditar, em preparação para mais idéias de design na próxima semana. :)",
      },
      {
        title: "Mas e se eu tiver mais perguntas?",
        content:
          "Não se preocupe. Sinta-se a vontade para enviar um email para suporte@jubarte.design ou para nos contatar via chat.",
      },
    ],
  },
  {
    accordionCategory: "Planos",
    accordionCard: [
      {
        title: "Posso mudar meu plano?",
        content:
          "Claro! Você pode alterar seu plano sempre que precisar. Basta entrar em contato conosco no email suporte@jubarte.design",
      },
      {
        title: "Existem taxas ocultas?",
        content:
          "Absolutamente sem taxas escondidas. O preço do plano é exatamente o que você paga mensalmente. Todos os impostos e taxas estão incluídos nesse preço.",
      },
      {
        title: "Se eu mudar de ideia e quiser cancelar minha assinatura, vocês devolvem meu dinheiro?",
        content:
          "Nós apenas podemos devolver o seu dinheiro se você mudar de ideia antes de fazer qualquer pedido de design ou em um prazo de até 15 dias após o início da sua assinatura. Assim que for feito o pagamento da sua assinatura sua conta estará ativa, o que significa que sua equipe estará a postos aguardando seus pedidos de design.",
      },
    ],
  },
]

const HelpPage = (props) => (
  <Layout location={props.location}>
    <Metomic />
    <SEO
      title="Central de Ajuda | Jubarte Design"
      locale="pt"
      altLocale="en"
      pathname="/help/"
      description="Confira nossa Central de Ajuda para entender todos os serviços da Jubarte Design. Veja como nosso time pode ajudá-lo com design gráfico e web."
      image="/unlimited-graphic-web-design-pt.jpg"
      imageSquared="/unlimited-graphic-web-design-squared-pt.jpg"
      imageAlt="Jubarte Design Logo - Design Gráfico e Web Ilimitado com uma Taxa Fixa Mensal"
    />
    <SplashScreens />
    <Header showLanguageSelector />
    <div className="position-relative text-center u-space-3-top">
      <div className="container u-space-3-top u-space-2-top--md u-space-0-bottom">
        <div className="w-md-80 w-lg-60 text-center mx-auto form-search-wrapper">
          <h1 className="display-4 font-size-48--md-down jd-h2-titles">
            Como podemos <span className="font-weight-bold">ajudar?</span>
          </h1>
        </div>
      </div>
    </div>

    {/* FAQ Topics Section */}
    <div className="section-faq-list container u-space-1">
      <Accordions data={accordionData} />
    </div>
    {/* End FAQ Topics Section */}
  </Layout>
)

export default HelpPage
